.selectisopen .ant-tree-select div:last-child {
  position: relative !important;
  left: 0 !important;
  top: 0 !important;
  overflow: auto !important;
}
 .selectisopen .ant-select-dropdown{
   box-shadow:none !important;
   max-height:100% !important;
 }

 .selectisopen .anticon svg {
  display: inline-block;
  color: #fff;
  background: #6c6868;
}
.selectisopen .ant-select-tree-title {
  font-size: 14px;
}
.selectisopen .ant-select-tree-show-line .ant-select-tree-switcher-line-icon {
  height: 20px;
  width: 20px;
}
.selectisopen .ant-select-tree-show-line .ant-select-tree-switcher {
  background: #fff;
  display: flex;
  align-items: center;
  width: 25px;
  height: 25px;
  justify-content: center;
}
.selectisopen .anticon svg {
  display: inline-block;
  color: #fff;
  background: #a6a6a6;
  font-size: 17px;
}
.selectisopen .ant-select-tree .ant-select-tree-node-content-wrapper {
  min-height: 21px;
  line-height: 18px;
  text-transform: capitalize;
  padding-top: 1px;
}
.selectisopen .ant-select-tree-switcher-loading-icon svg{
  color:green;
  background: none !important;
}
.selectisopen .ant-select-tree .ant-select-tree-treenode {
  display: flex;
  align-items: flex-start;
  padding: 3px 0px;
  outline: none;
}
.selectisopen .ant-select-tree-list-holder{
  max-height:100% !important;
}
.ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle {
  display: inline-flex;
  width: 18px;
  height: 18px;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
  margin-right: 4px;
}
.ant-select-tree-switcher-line-icon svg path:last-child{
  display: none;
}
ant-select-tree-treenode-leaf-last{
  overflow: hidden;
}