/* monitor nav */
.navmonitor {
  padding: 0;
  margin: 0;
  border: 0;
  line-height: 1;
}
.leaflet-container {
    height: 100%;
}
.navmonitor ul,
.navmonitor ul li,
.navmonitor ul ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.navmonitor ul {
  position: relative;
  z-index: 500;
  float: left;
  width: 100%;
}

.navmonitor ul li {
  float: left;
  min-height: 0.05em;
  line-height: 1em;
  vertical-align: middle;
  position: relative;
  text-align: center;
}

.navmonitor ul li.hover,
.navmonitor ul li:hover {
  position: relative;
  z-index: 510;
  cursor: default;
}

.navmonitor ul ul {
  visibility: hidden;
  position: absolute;
  top: 100%;
  left: 0px;
  z-index: 520;
  width: 100%;
}

.navmonitor2 ul ul {
  visibility: hidden;
  position: absolute;
  bottom: 0 !important;
  left: 0px;
  z-index: 520;
  width: 100%;
  top: auto !important;
}

.navmonitor ul ul li {
  float: none;
}

.navmonitor ul ul ul {
  top: 0;
  right: 0;
}

.navmonitor ul li:hover > ul {
  visibility: visible;
}

.navmonitor ul ul {
  top: 0;
  left: 99%;
}

.navmonitor > ul > li {
  float: none;
  border-bottom: 1px solid #4d5768;
}

.navmonitor ul ul {
  margin-top: 0.05em;
}

.navmonitor {
}

.navmonitor:before {
  content: "";
  display: block;
}

.navmonitor:after {
  content: "";
  display: table;
  clear: both;
}

.navmonitor a {
  display: block;
  color: #ffffff !important;
  text-decoration: none !important;
  text-transform: capitalize;
  font-size: 13px;
  padding: 13px 10px;
  cursor: pointer;
}

.navmonitor img {
  border-radius: 50%;
  margin: 0 auto;
}

.navmonitor ul ul {
  width: 250px;
  background: #323e55;
}
.navmonitor img {
  border-radius: 50%;
  margin-bottom: 3px;
}
.navmonitor span {
  display: block;
  text-align: center;
  font-size: 13px;
  margin: 2px 0px 0px;
}

.navmonitor > ul > li > a {
  color: rgba(0, 0, 0, 0.1) none repeat scroll 0% 0% !important;
}
.navmonitor ul ul li h4 {
  padding: 12px 10px;
  background: #323e55;
}
.navmonitor > ul > li > a:hover {
  color: #ffffff;
}

.navmonitor > ul > li a:hover,
.navmonitor > ul > li:hover a {
  background: #3d4b64;
}

.navmonitor li {
  position: relative;
}

.navmonitor ul li.has-sub > a:after {
  content: "";
  position: absolute;
  right: 1em;
}

.navmonitor ul ul li.first {
  -webkit-border-radius: 0 3px 0 0;
  -moz-border-radius: 0 3px 0 0;
  border-radius: 0 3px 0 0;
}

.navmonitor ul ul li.last {
  -webkit-border-radius: 0 0 3px 0;
  -moz-border-radius: 0 0 3px 0;
  border-radius: 0 0 3px 0;
  border-bottom: 0;
}

.navmonitor ul ul {
}

.navmonitor ul ul span {
  text-align: left;
}
.navmonitor ul ul a {
  font-size: 13px;
}

.navmonitor ul ul a {
  color: #ffffff;
}

.navmonitor ul ul a:hover {
  color: #ffffff;
}

.navmonitor ul ul li {
}
.navmonitor ul ul li h4 {
}

.navmonitor ul ul li:hover > a {
  background: #323e55;
  color: #ffffff;
}

.navmonitor.align-right > ul > li > a {
  border-right: none;
}

.navmonitor.align-right {
  float: right;
}

.navmonitor.align-right li {
  text-align: right;
}

.navmonitor.align-right ul li.has-sub > a:before {
  content: "+";
  position: absolute;
  top: 50%;
  left: 15px;
  margin-top: -6px;
}

.navmonitor.align-right ul li.has-sub > a:after {
  content: none;
}

.navmonitor.align-right ul ul {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: -100%;
  z-index: 598;
  width: 100%;
}

.navmonitor.align-right ul ul li.first {
  -webkit-border-radius: 3px 0 0 0;
  -moz-border-radius: 3px 0 0 0;
  border-radius: 3px 0 0 0;
}

.navmonitor.align-right ul ul li.last {
  -webkit-border-radius: 0 0 0 3px;
  -moz-border-radius: 0 0 0 3px;
  border-radius: 0 0 0 3px;
}

.navmonitor.align-right ul ul {
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}
.monitermenu {
  position: fixed;
  left: 0;
  top: 0;
  width: 100px;
  z-index: 5;
  height: 100%;
  color: #fff;
  background: #3d4b64;
  padding: 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mapsif iframe {
  width: 100%;
  height: 100%;
}
.customaps {
  width: 100%;
  height: 100vh !important;
}
body {
  width: 100%;
  height: 100vh;
}
.navmonitor ul ul li hr {
  margin: 0px !important;
}
.navmonitor ul ul li h4 {
  margin: 0px !important;
  color: #fff !important;
}
.navmonitor ul ul a {
  color: #ffffff;
  text-align: left;
}
/* Car icons*/
.car_blue_left {
  background-image: url(../../assets/images/icons/vehicle/car/blue/left.png);
}
.car_blue_right {
  background-image: url(../../assets/images/icons/vehicle/car/blue/right.png);
}
.car_blue_top {
  background-image: url(../../assets/images/icons/vehicle/car/blue/top.png);
}
.car_blue_bottom {
  background-image: url(../../assets/images/icons/vehicle/car/blue/bottom.png);
}
.car_green_left {
  background-image: url(../../assets/images/icons/vehicle/car/green/left.png);
}
.car_green_right {
  background-image: url(../../assets/images/icons/vehicle/car/green/right.png);
}
.car_green_top {
  background-image: url(../../assets/images/icons/vehicle/car/green/top.png);
}
.car_green_bottom {
  background-image: url(../../assets/images/icons/vehicle/car/green/bottom.png);
}
.car_red_left {
  background-image: url(../../assets/images/icons/vehicle/car/red/left.png);
}
.car_red_right {
  background-image: url(../../assets/images/icons/vehicle/car/red/right.png);
}
.car_red_top {
  background-image: url(../../assets/images/icons/vehicle/car/red/top.png);
}
.car_red_bottom {
  background-image: url(../../assets/images/icons/vehicle/car/red/bottom.png);
}
.car_grey_left {
  background-image: url(../../assets/images/icons/vehicle/car/grey/left.png);
}
.car_grey_right {
  background-image: url(../../assets/images/icons/vehicle/car/grey/right.png);
}
.car_grey_top {
  background-image: url(../../assets/images/icons/vehicle/car/grey/top.png);
}
.car_grey_bottom {
  background-image: url(../../assets/images/icons/vehicle/car/grey/bottom.png);
}

.car_orange_left {
  background-image: url(../../assets/images/icons/vehicle/car/orange/left.png);
}
.car_orange_right {
  background-image: url(../../assets/images/icons/vehicle/car/orange/right.png);
}
.car_orange_top {
  background-image: url(../../assets/images/icons/vehicle/car/orange/top.png);
}
.car_orange_bottom {
  background-image: url(../../assets/images/icons/vehicle/car/orange/bottom.png);
}

.car_greye_left {
  background-image: url(../../assets/images/icons/vehicle/car/greye/left.png);
}
.car_greye_right {
  background-image: url(../../assets/images/icons/vehicle/car/greye/right.png);
}
.car_greye_top {
  background-image: url(../../assets/images/icons/vehicle/car/greye/top.png);
}
.car_greye_bottom {
  background-image: url(../../assets/images/icons/vehicle/car/greye/bottom.png);
}


.iconCommon {
  width: 45px;
  height: 45px;
  border-radius: 5px;
  background-size: contain;
  background-repeat: no-repeat;
}

.poperwidth {
  width: 400px;
}
.poperwidth .MuiPopover-paper {
  width: 400px;
  border-radius: 10px;
  padding: 11px 16px;
}

.refreshmap {
  position: fixed;
  z-index: 5;
  background: rgba(255, 255, 255, 0.8);
  left: 0;
  right: 0;
  width: 280px;
  margin: 0 auto;
  text-align: center;
  top: 100px;
  font-size: 15px;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #999;
}
.refreshmap span {
  color: red;
  padding: 0px 5px;
  font-weight: 600;
  cursor: pointer;
}
.refreshmap a {
  color: blue !important;
}

.curmaplocation {
  position: fixed;
  z-index: 5;
  background: #fff;
  left: 0;
  right: 0;
  width: 517px;
  margin: 0 auto;
  text-align: center;
  top: 0px;
  font-size: 15px;
  padding: 5px 10px;
  border-radius: 0px 0px 25px 25px;
  border: 1px solid #999;
  border-top: 0px;
}
.curmaplocation span {
  padding: 0px 5px;
}
.curmaplocation b {
  font-weight: normal;
  color: #1818f4;
}
.topbar {
  position: fixed;
  z-index: 100;
  background: #fff;
  width: 100%;
  display: flex;
  padding: 12px 10px;
  align-items: center;
  justify-content: center;
}
.txt_link {
  color: #2cabe3;
  cursor: pointer;
}
.link_txt_option span {
  color: #2cabe3;
  cursor: pointer;
  border-right: 1px solid rgb(87, 87, 87);
  padding-right: 10px;
  padding-left: 10px;
}

.play_option {
  position: absolute;
  display: none;
  top: 100%;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  min-width: 150px;
  width: 530px;
  left: -220px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  margin-top: -28px;
  z-index: 2500;
}
.optionTxt {
  cursor: pointer;
  padding-top: 2px;
  padding-bottom: 10px;
}
.option_main:hover .play_option {
  display: block !important;
}
.bg_cus_2 {
  background-color: #f4f4f5;
}
.helpIcon {
  color: #3d4b64 !important;
  font-size: 17px;
  cursor: pointer;
}
.cursor-poiter {
  cursor: pointer;
}
.play_back_tab_nav {
  position: fixed;
  z-index: 9999;
  display: flex;
  top: 0;
  left: 15px;
  bottom: 50px;
  align-items: center;
  pointer-events: none;
}
.play_back_tab_right {
  position: fixed;
  z-index: 9999;
  display: flex;
  top: 0;
  right: 15px;
  bottom: 50px;
  align-items: center;
  pointer-events: none;
}
.tabs_pb {
  text-align: center;
  height: 40px;
  background: #333;
  line-height: 44px;
  width: 40px;
  cursor: pointer;
  border: 1px solid #dcdfe6;
  pointer-events: auto;
  justify-content: center;
  align-items: center;
  display: flex;
}
.tabs_pb:hover {
  background: #e98607;
}
.tabs_pb i {
  font-size: 20px;
  color: #fff;
}
.tabs_pb:hover i {
  color: #ffffff;
}
.play_back_tab_info {
  position: fixed;
  bottom: 50px;
  z-index: 250;
  left: 0;
  right: 0;
}
.pb_player {
  width: 56%;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 0px 15px;
  height: 40px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2), -1px -1px 2px rgba(0, 0, 0, 0.2);
  background-color: #596673;
}
.progressSlider {
  padding: 17px 0;
}
.slider-parent {
  height: 6px;
  background-color: #acb3b9;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
}
.play_drag {
  width: 5px;
  height: 15px;
  background: #ffffff;
  display: block;
  top: -4px;
  position: absolute;
  right: 0;
  border-radius: 5px;
}
.slider-son {
  position: relative;
  height: 6px;
  background-color: #ff9934;
  width: 0;
  border-radius: 4px;
}
.play_icon {
  width: 25px;
}
.play_back_tabs_close {
  position: absolute;
  right: 10px;
  top: 8px;
  cursor: pointer;
  width: 25px;
  text-align: center;
}
.activemaps {
  background: #e98607;
}

.mm_list {
  position: absolute;
  background: white;
  color: #333;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
  padding: 5px;

  display: none;
}
.mm_parent:hover .mm_list {
  display: block;
}

.group_device table tbody tr td {
  padding: 6px !important;
}
.group_device table tbody tr td button {
  padding: 0px !important;
}

.row-dark {
  background: #eeeded !important;
  color: #74788d !important;
}

.list-view {
  border: 1px solid #e98607 !important;
}

.cell-border-none {
  border: none !important;
}
.staticdevice {
  background: #303133;
  display: block;
}
.staticdevice {
  padding: 10px;
}
.devicesta li {
  padding: 5px 0px;
  list-style: none;
  color: #fff;
  font-weight: normal;
}
.devicesta li b {
  font-weight: normal;
}
.ant-popover-placement-right,
.ant-popover-placement-rightTop,
.ant-popover-placement-rightBottom {
  padding-left: 0px !important;
}
iframe {
  display: none;
}
.absolilist {
  position: absolute;
  right: 10px;
  top: 4px;
  z-index: 25000;
}
.bgtoggle {
  background: #e4ecf9;
}
 .devicenam{
  /* font-weight: bold !important; */
 }
.status-static .devicenam a {
  color: #0795fb !important;
}
.status-static .timedurea {
  color: #0795fb !important;
}
.status-static .timedurea .devicenoto {
  background: #0795fb !important;
}

.status-static-idle .devicenam a {
  color: #fcb901 !important;
}
.status-static-idle .timedurea {
  color: #fcb901 !important;
}
.status-static-idle .timedurea .devicenoto {
  background: #fcb901 !important;
}

.status-moving .devicenam a {
  color: #69cf01 !important;
}
.status-moving .timedurea {
  color: #69cf01 !important;
}
.status-moving .timedurea .devicenoto {
  background: #69cf01 !important;
}
.status-speeding .devicenam a {
  color: #f44336 !important;
}
.status-speeding .timedurea {
  color: #f44336 !important;
}
.status-speeding .timedurea .devicenoto {
  background: #f44336 !important;
}

.status-offline .devicenam a {
  color: #cccecf !important;
}
.status-offline .timedurea {
  color: #cccecf !important;
}
.status-offline .timedurea .devicenoto {
  background: #cccecf !important;
}

.status-expired .devicenam a {
  color: #cccecf !important;
}
.status-expired .timedurea {
  color: #cccecf !important;
}
.status-expired .timedurea .devicenoto {
  background: #cccecf !important;
}
.status-expired .form-check-input {
  background: #cccecf !important;
}
.status-expired .nofiicons i {
  color: #bd0835;
  font-size: 14px;
}

.status-unactivated .devicenam a {
  color: #cccecf !important;
}
.status-unactivated .timedurea {
  color: #cccecf !important;
}
.status-unactivated .timedurea .devicenoto {
  background: #cccecf !important;
}
.status-unactivated .form-check-input {
  background: #cccecf !important;
}
.status-unactivated .nofiicons i {
  color: #999;
  font-size: 14px;
}

.colored {
  color: #bd0835;
}

.hoverdiv li:hover {
  background: #e98607;
  color: #fff;
}
.playicons {
  background: url("../../assets/images/icons.png");
  background-position: 0px 0px;
}
.popuserright li:hover .playicons {
  background: url("../../assets/images/icons.png");
  background-position: -25px 0px;
}

.trackicons {
  background: url("../../assets/images/icons.png");
  background-position: 0px -25px;
}
.popuserright li:hover .trackicons {
  background-position: -25px -25px;
}

.commandicons {
  background: url("../../assets/images/icons.png");
  background-position: 0px -50px;
}
.popuserright li:hover .commandicons {
  background-position: -25px -50px;
}

.detailsicons {
  background: url("../../assets/images/icons.png");
  background-position: 0px -75px;
}
.popuserright li:hover .detailsicons {
  background-position: -25px -75px;
}

.movegroicons {
  background: url("../../assets/images/icons.png");
  background-position: 0px -100px;
}
.popuserright li:hover .movegroicons {
  background-position: -25px -100px;
}
.reporticons {
  background: url("../../assets/images/icons.png");
  background-position: 0px -125px;
}
.popuserright li:hover .reporticons {
  background-position: -25px -125px;
}

.suppicons {
  background: url("../../assets/images/icons.png");
  background-position: 0px -150px;
}
.popuserright li:hover .suppicons {
  background-position: -25px -150px;
}
.helpicons {
  background: url("../../assets/images/icons.png");
  background-position: 0px -175px;
}
.popuserright li:hover .helpicons {
  background-position: -25px -175px;
}

.act-device {
  background: #cceaff;
}

.mycluster {
  width: 40px;
  height: 40px;
  background-color: greenyellow;
  text-align: center;
  font-size: 24px;
}

.custom-marker-cluster{
  background: #ffffff;
  border: 15px solid #f00800;
  border-radius: 50%;
  color: #000000;
  height: 33px;
  line-height: 37px;
  text-align: center;
  width: 33px;
}