
//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";

// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/dragula";
@import "custom/plugins/session-timeout";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/rating";
@import "custom/plugins/toastr";
@import "custom/plugins/parsley";
@import "custom/plugins/select2";

@import "custom/plugins/switch";
@import "custom/plugins/colorpicker";
@import "custom/plugins/timepicker";
@import "custom/plugins/datepicker";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/datatable";
@import "custom/plugins/responsive-table";
@import "custom/plugins/table-editable";
@import "custom/plugins/apexcharts";
@import "custom/plugins/echarts";
@import "custom/plugins/flot";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";
@import "custom/plugins/vector-maps";
@import "custom/plugins/leaflet-maps";


// Pages
@import "custom/pages/authentication";
@import "custom/pages/ecommerce";
@import "custom/pages/email";
@import "custom/pages/file-manager";
@import "custom/pages/chat";
@import "custom/pages/projects";
@import "custom/pages/contacts";
@import "custom/pages/crypto";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/extras-pages";


.search-box .form-control {
    padding:7px 0px 7px 40px;
  }
  .page-link {
    padding: 0px 5px;
    font-size: 14px;
  }
  .react-bs-table-sizePerPage-dropdown button {
    font-size: 12px;
    padding: 1px 5px 3px;
  }
  .react-bs-table-sizePerPage-dropdown button {
    font-size: 12px;
    padding: 1px 5px 3px;
  }
  .cursorpo{
    cursor: pointer;
  }

  .form-control {
  padding: 7px 10px;
  font-size: 13px;
  font-weight: 400;
  line-height: 14px;
  min-height: 34px;
}
  .form-label {
    margin-bottom: 0.5rem;
    font-size: 15px;
  }
  .search-box .search-icon {
    line-height: 35px;
  }
  body[data-sidebar="dark"] .navbar-brand-box {
    background:#3D4B64;
    border-bottom: 1px solid #223B66;
  }
  body{
    background: #EEF5F9 !important;
  }
  #sidebar-menu ul li ul.sub-menu {
    padding: 0;
    background:#293A59;
  }
  body[data-sidebar="dark"] #sidebar-menu ul li a i {
    color: #fff;
  }
  .navbar-header {
    border-bottom: 1px solid #dedede;
  }
  .toolp{
    display: inline-block;
  }
  .toolp span{
    font-size: 11px;
  }
  .linkbtn a{
    padding: 0px;
  }

  // search
  /**
 * Main wrapper
 */
.select-search {
  width: 300px;
  position: relative;
  font-family: "Nunito Sans", sans-serif;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
* Value wrapper
*/
.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
* Input
*/
.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 40px 0 16px;
  background: #fff;
  border: 1px solid transparent;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
* Options wrapper
*/
.select-search__select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

/**
* Options
*/
.select-search__options {
  list-style: none;
  padding: 0;
}

/**
* Option row
*/
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
* Option
*/
.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #2fcc8b;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #2eb378;
  color: #fff;
}

/**
* Group
*/
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
* States
*/
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
* Modifiers
*/
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple)
  .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #2fcc8b;
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}
.vecinfo{

}
.vecname{
  color:#0B2E6D;
}
.vechilceimg {
  width: 80px;
  border: 1px solid #dedede;
  margin-right: 12px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius:5px;
}
.vechilceimg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.statusvehi{
  display: inline-flex;
justify-content: center;
align-items: center;
}
.statusvehi .mdi.mdi-chevron-down{
  font-size: 16px;
}
.statusvehi b {
  padding: 4px;
  border-radius: 50%;
  margin-right: 5px;
  width: 10px;
  height: 10px;
  display: inline-block;
}
.statusvehi .btn {
  padding: 0px;
  background: none;
  border: 0px;
  font-size: 15px;
}
.statussec span{
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 7px;
}
.warehouseas, .operatorsed{
  font-size: 15px;
}
.servicealert {
  padding: 10px 20px;
  border: 1px solid #dedede;
  display: inline-block;
}
.servicealert h5 {
  font-size: 12px;
  text-transform: uppercase;
  color: #999;
}
.servicealert span {
  font-size: 23px;
}
.servicealert b{
  font-weight: normal;

}
.servtotal{
  opacity: 0.5;
}
.qaddd{

}
.sidebaroverview {
  padding: 0px;
}
.sidebaroverview ul {
  padding: 0px;
  margin: 0px;
}
.sidebaroverview li {
  padding: 0px;
  list-style: none;
  border-bottom: 1px solid #dedede;
}
.sidebaroverview li a {
  color: #0B2E6D;
  padding: 8px 10px;
  display: flex;
  font-size: 15px;
  justify-content: space-between;
  align-items: center;
}
.sidebaroverview li span {
  color: #333;
  float: right;
  min-width: 25px;
  background: #dedede;
  text-align: center;
  border-radius: 5px;
  font-size: 12px;
}

.qadd {
  background: #198754;
  margin-right: 25px;
  padding: 8px 15px;
  display: inline-block;
  border-radius: 5px;
  color: #fff;
  font-size: 13px;
}
.titletale{
  display: flex;
justify-content: space-between;
padding:9px 17px;
align-items: center;
border-bottom:1px solid #dedede;
}
.titletale span{

}
.tablecommon tr td {
  padding:5px 19px 7px;
  color: #666;
}
.editbtn {
  border: 1px solid #dedede;
  font-size: 12px;
  padding: 2px 7px;
  color: #666;
  border-radius: 2px;
}
.viewall {
  font-size: 14px;
  color:#3D4B64;
  text-decoration: underline !important;
}
.statusnumber h3{
  font-weight: 400;
  font-size: 22px;
  margin: 0px;
}
.statusnumber span{
  font-weight: 400;
}
.dropdown-item {
  font-size: 14px;
  padding: 5px 11px;
}
.titletale span {
  font-weight: 600;
}
.dropzone{
  min-height: auto;
}
.dz-message {
  text-align: center;
  padding: 5px 30px 15px !important;
}
.form-select {
  padding: 0px 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  min-height: 34px;
  line-height: 32px;
}
.w-100p{
  width:auto;
}
.imggalley {
  width: 100%;
  height: 200px;
  border: 1px solid #dedede;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imggalley img {
  height: 100%;
  object-fit: contain;
}

a.monitorlinks {
  font-size: 14px;
}
.monitorlinks i{
margin-right: 5px;
font-size: 23px;
}
.app-search {
  width: 500px;
}
.app-search input {
  background: none !important;
  border:1px solid #dedede !important;
}
.formsea{
  width:250px;
}
.btn {
  padding: 7px 12px;
  font-size: 13px;
  height:34px;
}
.btnwid{
  min-width:100px;
}
.app-search button {
  min-width: 100px;
  height: 38px;
}
.form-label > b{
  color:red;
}
.page-title-box h3{
  font-weight: 400;
}
.metismenu li.mm-active{
  background:#515D74;
}
.bg-card {
  background: rgba(0,0,0,0.05);
  text-align: center;
}
.profileimg{
  text-align: center;
}
.profileimg img {
 
}
.sideuser {
  background:#293A59;
  padding:23px 0px;
}
.profileinfo {
  display: flex;
  justify-content: space-evenly;
  margin-top: 22px;
}
.profileinfo a {
  display: block;
  color: #fff;
  text-align: center;
}
.profileinfo button {
  padding: 0px;
  background: none;
  border: 0px;
  color: #fff;
  text-align: center;
}
.profileinfo img {
  width: 29px;
  background: #3D4B64;
  border-radius: 50%;
  padding: 5px;
  margin: 0px 0px 6px;
}
.profileinfo span {
  display: block;
  font-size: 13px;
}
.header-profile-user {
  border: 1px solid #dedede !important;
}
.search-box .form-control {
  padding: 7px 0px 7px 40px;
  border-radius: 0px;
}
.pagination .page-item {
  margin-left: 8px;
}
.pagination .page-link {
  padding: 2px 8px;
  font-size: 14px;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow:none !important;
}
.btnsmall .btn-outline-secondary{
  padding: 4px 12px !important;
}
.table tr td {
  font-size: 14px;

}
.modal-content{
  border-radius:0px;
  border:0px;
}
.modal-header {
  background: #3D4B64;
  border-radius: 0px;
  color: #fff;
  padding:13px 1rem;
}
.modal-header h5{
  color:#fff;
}
.modal-header .close {
  background: none;
  padding: 0px;
  color: #fff;
  opacity: 1;
  font-size: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  right: 9px;
  top: 12px;
}
.modal-header .close i{
  font-size: 18px;
}
.card-drop {
  color: #495057;
  cursor: pointer;
}
.dzb-menu-tree{
  margin: 0px;
  padding:0px;
}
.rstm-tree-item {
height: 35px;
line-height: 34px;
}
.rstm-tree-item--active {
color: white;
background: #344055;
border-bottom: none;
}

.rstm-tree-item {
padding: 2px 1rem;
}
.dzb-menu-tree li{
  list-style: none;
}
ul, li{
  padding: 0px;
  margin: 0px;
}
.account-pages {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-items: flex-end;
  align-items: center;
  background: url("../images/bgaccount.jpg");
  background-position: center center;
  background-size: cover;
}
.mrlogin{
  margin-right: 125px;
}
.loginbg{
  background: #7987A2 !important;
}
.loginbg p{
 margin-bottom: 0px !important;
}
.headinglogin{
  background: #42456a !important;
}
.headinglogin h5{
  color: #fff !important;
}
.headinglogin p{
  color: #fff !important;
}
.submitbtn{
 
}
.width350 .popover {
  max-width: 450px !important;
}
.popoverclose{
  position: absolute;
right: -11px;
top: -13px;
background: #333;
width: 25px;
height: 25px;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
color: #fff;
cursor: pointer;
}
.w-150x{
  width:225px;
}
.height32{
  min-height: 32px;
height: 30px;
font-size: 13px;
}
.simpleri i{
  font-size: 25px;
  margin-right: 15px;

}
.simpleri{
  font-size: 13px;
}
.popifio {
  background: #ddd;
  padding: 10px;
  border-left: 5px solid #979595;
}
.popifio ul {
  padding: 0px;
  margin: 0px;
}
.popifio li {
  list-style: none;
  margin-bottom: 5px;
}
.card-drop i{
  padding-top: 4px;
}
.btnadd{
  height: 34px;
line-height: 27px;
padding: 0px 14px;
}
.checuserimg a {
  display: flex;
  flex-direction: column;
  background: #f7f2f2;
  border: 1px solid #dedede;
  padding: 4px;
  margin: 0px 5px 5px 0px;
  border-radius: 5px;
}
.cursorp{
  cursor: pointer;
}
.nav-tabs-custom {
  border-bottom: 2px solid #e3dfdf;
  background: #fff;
}
.btnpasswaord {
  height: 34px;
  line-height: 17px;
  font-size: 13px;
  padding: 0px;
  width: 41px;
}
.btnpasswaord svg{
  font-size: 15px;
}
.imgprofile {
  width: 65px;
  height: 65px;
  margin: 0 auto 10px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}
.imgprofile img {
  max-width: 100%;
  max-height: 100%;
}

.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  border-color: #ced4da !important;
}
.ant-select-tree .ant-select-tree-node-content-wrapper{
  display: flex;
align-items: center;
}
.ant-select-tree-treenode-leaf-last{
  overflow: hidden;
}
body, .main-content, #root, #layout-wrapper, .page-content {
  height: 100% !important;
  overflow: auto;
}
.widthside {
  width: 325px;
  min-width: 325px;
}
.flex1 {
  -webkit-flex: 1;
  flex: 1;
}
.h38{
  height:38px;
  border-bottom:2px solid #dedede;
}
.posup.dropdown-menu.show {
  z-index: 2500;
}
.br_1 {
  border-right: 1px solid #eff2f7 !important;
}
.opacity-80{
  opacity:0.8;
}
.orangecol{
  color:#E98607;
}
.bluecol{
  color:#2EC7E3
}

.nav-tabs-custom .nav-item .nav-link::after {
  content: "";
  background: #E98607;
  bottom:-2px;
}
.nav-tabs-custom .nav-item .nav-link.active {
  color: #E98607;
}
.page-item.active .page-link {
  background-color: #E98607;
  border-color: #E98607;
}
.btn-outline-secondary {
  border-color: #dbdbdd;
}
.fontnormal{
  font-weight: 400;
}
.btn-primary {
  background-color: #E98607;
  border-color: #E98607;
}
.form-check-input:checked {
  background-color: #E98607;
  border-color: #E98607;
}
.header-fixed {
  width: 100% 
}

.header-fixed > thead,
.header-fixed > tbody,
.header-fixed > thead > tr,
.header-fixed > tbody > tr,
.header-fixed > thead > tr > th,
.header-fixed > tbody > tr > td {
  display: block;
}

.header-fixed > tbody > tr:after,
.header-fixed > thead > tr:after {
  content: ' ';
  display: block;
  visibility: hidden;
  clear: both;
}

.header-fixed > tbody {
  overflow-y: auto;
  height: 150px;
}

.header-fixed > tbody > tr > td,
.header-fixed > thead > tr > th {
  width: 25%;
  float: left;
}
.w100{
  width:100px;
}
.table tr th {
  padding: 7px 10px;
  color: #333 !important;
  font-weight: 500;
}
.table tr td {
  font-size: 13px;
}
.minheightpop{
  min-height:400px;
}
.btn-sm, .btn-group-sm > .btn {
  border-radius: 0px !important;
}
.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 14px !important;
}
.checuserimg img {
  width: 35px;
  height: 35px;
  opacity: 0.9;
}
.deviceres .nav-tabs-custom .nav-link {
  padding: 5px 8px;
  font-size: 14px;
}
.apexcharts-yaxis text, .apexcharts-xaxis text {
  font-weight: 400;
}
.apexcharts-legend {
  bottom: -3px !important;
}
.bg-dashboard{
  background: #3D4B64;
  height: 100%;
}
.bg-dashboard2{
  background: #546788;
  height: 100%;
}

.bg-dashboard3{
  background:#687995;
  height: 100%;
}

.ant-select-tree-title {
  display: flex;
  justify-content: space-between !important;
  width: 100%;
  align-items: center;
  position: relative;
}
.ripos {
  position: relative !important;
  z-index: 25000 !important;
  padding-right: 6px;
}
.bordersha .popover-inner{
  border:1px solid #dedede;
}
.bordersha .popover-body{
  padding:10px;
}

.bordersha .popover-body .dropdown-item {
  padding: 4px 5px !important;
  font-size: 14px;
}


.navhometabs .nav-item .nav-link {
  border: none;
  padding:14px 14px;
}
.navhometabs {
  background: #f9f8f8;
}
.nextbtn {
  position: absolute;
  right: 11px;
  top: 11px;
  border: 1px solid #dedede;
  padding: 2px 11px;
}
.bgsuplli {
  background:#546788;
  margin-bottom: 50px;
  text-align: center;
  min-height: 125px;
  position: relative;
}
.bgsuplli img {
  border: 1px solid #dedede;
  background: #fff;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -30px;
  border-radius: 50%;
}
.supplierinfo{
  text-align: center;
}
.supplierinfo span{
  display: block;
}
.supplierinfo span {
  display: block;
  padding: 6px 0px;
  font-weight: 600;
}
.homecardbody{
  background: #f9f8f8;
padding: 12px 25px 14px !important;
}
.billcharttop .btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.15rem #E98607;
}
.billcharttop button {
  margin: 0px 1px;
}
.dotteduser {
  display: none;
}
.ant-select-tree-treenode:hover .dotteduser{
  display: block;
}

.ant-popover-inner{
  background: none;
  box-shadow:none;
}
.ant-popover-inner-content{
  background: none;
}

.form-select {
  padding: 0px 33px 0px 10px;
  font-size: 13px;
  font-weight: 400;
  line-height: 14px;
  min-height: 34px;
  line-height: 32px;
}

.toastalert h3{
  font-weight: 400;
  font-family: var(--bs-body-font-family);
  border-bottom:1px solid rgba(244, 6, 6, 0.2);
  padding: 10px 0px;
  text-align: center;
  background:#F03B13;
  color: #fff;
  font-weight: 600;
  margin: 0px;
  font-size: 15px;
}
.toastalert p {
  font-family: var(--bs-body-font-family);
  margin: 0px;
  padding: 10px;
  text-align: center;
  color: #fff;
}

.trackalert {
  padding: 0px;
  position: relative;
  background:rgba(249, 59, 19, 0.5);
}

.trackalert .Toastify__toast-body{
  padding: 0px;
}
.trackalert .Toastify__close-button {
  position: absolute;
  right: 10px;
  top: 7px;
  color: #fff;
  opacity: 1;
}

.footer-copyrights {
  position: fixed;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  padding: 21px 0px;
}
.api-header{
  background: #000;
}

.api-breadcrump{
  background: url("../images/breadcrumb.jpg");
}
.api-info .title{
  background: #f7dcdc;
  margin-bottom: 10px;
}
.api-info .title h3{
  font-size: 21px;
  padding: 10px;
}
.info-left-api{
  background:#EAEAEA;
}
.info-left-api{
 padding: 15px;
}
.info-left-api li {
  list-style: none;
  margin-bottom: 6px;
  font-size: 15px;
}
.info-left-api li a {
  font-size: 15px;
  display: block;
  padding:2px 5px;
}

.info-left-api li a h3{
  font-size: 20px;
  color: #922791;
  margin-bottom: 0px;
  padding: 5px 0px;
}
.info-left-api li a.activetab h3{
  color: #ffff;
}
.info-left-api li a.activetab{
  color: #fff;
  background:#922791 ;
}
.api-info p{
  font-size: 15px;
}
.api-info pre {
  background: #000;
  color: #fff;
  padding: 15px;
}

.api-info .table tr td {
  font-size: 15px;
  border:1px solid #ccc;
}
.api-info .table tr th {
  font-size: 15px;
  border:1px solid #ccc;
}

.api-info h4{
  font-size: 19px;
}


.info-right-api{
}
.info-right-api{
 padding: 15px;
}
.info-right-api li {
  list-style: none;
  margin-bottom: 6px;
  font-size: 15px;
}
.info-right-api li a {
  font-size: 15px;
  display: block;
  padding: 5px;
  color: #0B2E6D;
  font-weight: 500;
}
.api-info a{
  font-weight: bold;
}
.open-api-cla {
  color: #fff;
  border: 1px solid #fff;
  padding: 8px 15px;
  display: inline-flex;
}

.open-api-cla:hover{
  color: #fff;
  border: 1px solid #922791;
  padding: 8px 15px;
  display: inline-flex;
  background: #922791;
}