.selectdialoguser{
    background: red;
}
.btndropuser{
    background: #e1e1e1 !important;
border-radius: 0px !important;
padding: 0px 10px !important;
min-width: auto !important;
color: #333 !important;
border: 1px solid #dedede !important;
}
.selectdialoguser {
    background: #fff;
    padding: 5px;
    border: 1px solid #dedede;
    width: 100%;
    position: absolute;
    left: 0;
    top: 34px;
    z-index: 5;
    height: 200px;
    overflow: auto;
    box-shadow: 1px 5px 5px rgba(0,0,0,0.1);
  }
  .dzb-menu-tree{
      margin: 0px;
      padding:0px;
  }
  .rstm-tree-item {
    height: 35px;
    line-height: 34px;
  }
  .rstm-tree-item--active {
    color: white;
    background: #344055;
    border-bottom: none;
  }

  .rstm-tree-item {
    padding: 2px 1rem;
  }

.popuserright li{
  list-style:none;
  padding: 5px 5px;
}
.popuserright {
  background: #fff;
  min-width: 199px;
  border-radius: 5px;
}
.popuserright li:first-child{
  border-radius: 5px 5px 0px 0px;
}
.popuserright li:last-child{
  border-radius: 0px 0px 5px 5px;
}
.popuserright li a{
  display: flex;
}
.popuserright li {
  display: flex;
}
.popuserright li .iicons{
  display: block;
  width:25px;
  height:25px;
  margin-right:3px;
}