.position-relative{
    position: relative;
}
.toggle_panel{
    position: absolute;
    bottom: 100%;
    background: #ffffff;
    min-width: 250px;
    margin-left: -100px;
    margin-bottom: 14px;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
}
.poi_panel{
    min-width: 320px;
    margin-left: -120px;
}
.tog_header
{
    background: #323E55;
color: #ffffff;
padding: 8px 4px;
}
.pr-4{
    padding-right:10px;
}
.pl-4{
    padding-left:10px;
}
.mr-3{
margin-right: 15px;
}
.min_col{
    position: relative;
    width: 42px;
    cursor: pointer;
}
.min_icon{
    font-size: 27px;
    position: absolute;
    bottom: -3px;
    right: -1px;
}
.ml-3{
margin-left: 15px;
}
.cus_input{
    font-size: 13pxt;
    padding: 4px 0px;
    height: 30px;
    margin: 0px;
    border: 1px solid #dedede !important;
}
.cus_btn{
    border: 1px solid #dedede;
color: #666;
border-radius: 0px;
padding: 0 6px;
width: auto;
min-width: auto;
height: 30px;
min-height: auto;
line-height: normal;
}
.align-items-center{
align-items: center;
}
.cursor-pointer{
cursor: pointer;
}
.bl-1{
border-left:1px solid #ccc;
}
.bg_cus_1{
    background: #f4f0f0;
}
.cus_ul{
    padding-left: 6px;
}
.loca_icon{
    color:#922791
}
.justify-content-start{
    justify-content: flex-start;
}
.poi_ul{
    height: 300px;
    overflow: auto;
}
.poi_wrap_he{
    height: 335px;
}
.poi_action{
    display: none;
}
.poi_ul li:hover .poi_action{
display: block;
}
.poi_action_btn *, .poi_action_btn{
opacity: 0.8;
cursor: pointer;
}
.poi_ul li{
border-bottom: 1px solid #ccc;
padding-top: 7px;
padding-bottom: 7px;
}
.poi_ul li:first-child{
padding-top: 0px;
}
.form_input{
    border:1px solid #ccc;
}
.h-auto{
height: auto;
}
.m-2{
margin:7px;
}
.edit_pop_close{
    position: absolute;
    top: -5px;
    right: 0;
}
.pt-5{
    padding-top: 25px;
}
.mapbottombar {
    position: fixed;
    bottom: 0;
    background: #556582;
    height: 50px;
    z-index: 5;
    display: flex;
  }
  .monitormaps{
    left: 100px;
    width: calc(100% - 100px);
  }
  .leftmap > span{
    margin:0px 0px 0px 25px;
  }
  .rightmap > span{
    margin:0px 15px 0px 0px;
  }
  .no-button {
    background: none;
    min-width: auto !important;
    padding: 2px !important;
    border: 1px solid #fff !important;
    min-width: auto;
    border-radius: 0px !important;
  }

  .mapzomm {
    display: flex;
    margin-left: 20px;
  }
  .mapzomm span {
    margin: 0px;
    color: #fff;
  }
  .mapzomm .MuiBox-root {
    margin: 0px 10px;
  }
  .no-button2 {
    padding: 0px !important;
    margin: 0px !important;
    border: 0px !important ;
    min-width: auto !important;
  }
  .barsearch input {
    background: none !important;
    border: 1px solid #fff !important;
    color: #fff !important;
  }
  .position-relative.barsearch {
    margin-left: 30px;
    width: 200px;
  }
  .barsearch .bx.bx-search-alt {
    position: absolute;
    color: #fff;
    right: 10px;
    top: 5px;
    font-size: 22px;
  }
  .barsearch input::-webkit-input-placeholder { /* Edge */
    color: #fff;
  }
  
  .barsearch input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #fff;
  }
  
  .barsearch input::placeholder {
    color: #fff;
  }
  
  .mb_map_center{
    margin-left: -80px !important;
    margin-bottom: 8px !important;
    width: 190px;
  }
  .mb_route_center{
    width: 320px;
    margin-right: -130px !important;
    margin-bottom: 8px !important;
  }
  .geo_fence_row{
    width: 320px;
    margin-right: -90px !important;
    margin-bottom: 8px !important;
  }
  .mb_alert_toggle{
    width: 320px;
    margin-bottom: 8px !important;
    margin-right: -35px !important;
  }
  .mb_trace_center{
    width: 320px;
    margin-bottom: 8px !important;  
  }
 
  .bot_pop_head{
    background: #323E55;
    padding:2px 10px;
    color:#ffffff
  }
  .map_list_item li{
color:#000000;
height: 32px;
line-height: 32px;
cursor: pointer;
padding-left: 15px;
font-size: 13px;
  }
  .map_list_item li:hover{
    background: #323E55;
    color:#ffffff;
  }
  .bg_cus_2 {
    background-color: #f4f4f5;
  }
  
  .helpIcon {
    color: #3d4b64 !important;
    font-size: 17px;
    cursor: pointer;
  }
  .link_txt {
    color: #2cabe3 !important;
    cursor: pointer;
    background: transparent !important;
  }
  .txt_clr {
    right: 7px;
    top: 0;
    bottom: 0;
    position: absolute;
    cursor: pointer;
  }
  .cus_icon_btn{
    padding:5px !important;
    border-radius: 0 !important;
    line-height: 0 !important;
    background-color: #3d4b64 !important;
  }
.cus_icon_btn i{
font-size:18px;
}
.br_1{
  border-right:1px solid rgba(255,255,255,0.2) !important
}
.poi_head_input{
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  line-height: normal !important;
  height: auto !important;
  min-height: auto !important;
}
.hover_poi{  
  opacity: 0;
}
.poi_list_item:hover .hover_poi{
  opacity: 1;
}
.list_disc {
  list-style: disc;
  margin-left: 16px;
}
.box_cus_1{
  padding: 10px 0 10px 10px;
  border-left: 4px solid #fc0;
  background-color: rgba(255,204,0,.08);
  color: #333;
}

.file_upload_btn input {
  overflow: hidden;
  position: absolute;
  opacity: 0;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  cursor: pointer;
}
.file_upload_btn {
  position: relative;
  overflow: hidden;
}
.overflow_auto{
  overflow: auto;
}
.poi_list_item{
  height: 251px;
overflow: auto;
}
.cus_btn_drop_1{
  background: transparent !important;
  padding: 0 !important;
  border: 0 !important;
  box-shadow: none !important;

}
.cus_btn_drop_1 i{
  color: #3d4b64 !important;
}
.route_list_item li{
padding-bottom: 5px;
margin-bottom: 5px;
border-bottom: 1px solid #ccc;
}
.GeoFencEdit{
  position: absolute;
  bottom: 0;
  background: rgba(0,0,0,0.4);
  margin-bottom: 14px;
  top: 0;
  left: 0;
  right: 0;
}
.GeoFencEdit_row{
  position: absolute;
  top: 73px;
  left: 0;
  right: 0;
  background: #fff;
}
.color_picker{
  width:15px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.sketch-picker {
  
  right: 0;
  left: auto;
}
.gf_col2{
  background-color: #e4e4e4;
padding: 5px 10px;
}

.gf_col3 ul, .gf_col3 li
{
list-style: none;
margin: 0 auto;
padding:0;
}

.gf_col3 li
{
padding:5px 10px;
cursor: pointer;
}
.active_gf, .gf_col3 li:hover{
  background-color: #3d4b64 !important;
  color:#ffffff; 
}
.gf_col3 ul
{
  height: 250px;
  overflow: auto;
}
.cus_nobtn{
  border:0px !important;
  
  box-shadow: none !important;
}
.cus_nobtn i{
  color: #495057;
}
.txt_over_1 {
  width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
} 
.cus_br{
  border: 1px solid #ddd;
border-radius: 5px;
padding:8px;

}
.line-0{
line-height: 0;
}
.ei_w{
  width:300px !important
}
.ul_h1{
  height: 285px;
  overflow: auto;
}
.download_row button{
  width:200px
}