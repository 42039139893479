.custompoptabs {
    display: flex;
    align-items: center;
    justify-content: center;
    background:#445471 !important;
    border:1px solid #3D4B64 !important;
  }
  .custompoptabs .nav-item .nav-link.active {
    color: #E98607 !important;
    background:#445471 !important;
  }
  .custompoptabs .nav-item .nav-link {
    color: #fff !important;
    background:#445471 !important;
}
.custompoptabs .nav-item {
   margin: 0px 35px;
}
.bgleft{
    background: #f2f2f2;
}
.searchi {
  position: absolute;
  z-index: 5;
  left: 10px;
  top: 8px;
}
.searchi i{
  font-size: 19px;
}


.custompoptabs .nav-item {
   margin: 0px 35px;
   padding-bottom: 0.5rem 1rem 10px;
}
.custompoptabs .nav-item .nav-link.active::after {
  content: '';
  border-bottom: 9px solid #fff;
  border-left: 11px solid transparent;
  border-right: 12px solid transparent;
  background: none;
  width: 17px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.minheightis{
  min-height:600px;
}