.formlab .bx-cookie{
  font-size: 18px;
}
.min-width200{
  width:200px !important;
}
.countcss {
  text-align: center;
  opacity: 0.7;
  margin: 0px 13px;
}
.countcss b{
  display: block;
  padding-bottom: 2;
}

.card-inc .btn{
  z-index: 0 !important;
}