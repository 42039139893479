.my_device {
  background: #fff;
  /*
  position: absolute;
  left: 120px;
  width: 300px;
 
  z-index: 1;
  top: 21px;
  display: flex;
  flex-direction: column;
  bottom: 70px;
  height: auto;*/
}
.my_deviceinner{
  z-index: 1;
  top: 21px;
  display: flex;
  flex-direction: column;
  bottom: 70px;
  height: 100%;
  background: #fff;
}
.my_device_main {
  position: absolute;
  left: 120px;
  width: 300px;
  z-index: 1;
  top: 21px;
  display: flex;
  flex-direction: column;
  bottom: 70px;
  height: auto;
}
.flx_0{
    flex: 0 0 auto;
}
.flx_1{
    flex: 1 1 auto;
}
.h3title {
    background: #3D4B64;
    color: #fff;
    padding: 8px 13px;
  }
  .bg_cus_1{
    background: #f4f0f0;
  }
  .over_auto{
overflow: auto;
  }
  .cus_btn_icon {
    padding: 0px;
    width: 28px;
    background: #fff;
    border: 0px;
    border: 1px solid #dedede;
    color: #666;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tooltip_i{
      font-size: 15px;
      color: #3693e6;
      }
      .deviceshown{
      width:100%;
      margin: 0px;
      padding:0px 0px;
      }
      .deviceshown li {
        margin: 0px 0px;
        padding: 4px 8px 2px;
        list-style: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .deviceshown li:hover{
        background:#cceaff;
      }
      .devicenoto {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin: 0px 5px;
      }
      .devicpoo .bx-dots-vertical-rounded {
        color: #333;
        font-size: 17px;
      }
      .staticdevice {
        position: relative;
        margin:6px 5px 0px 0px;
      }
      .staticdevice .bornon >  .bxs-info-circle{
        font-size: 18px;

      }
      .staticdevice dialog {
        position: absolute;
        left: 36px;
        background: #333;
        width: 190px;
        border: 1px solid #dedede;
        z-index: 5;
        padding:0px 15px 7px;
        display: none;
        top: -150px;
        border-radius:10px;
      }
      .staticdevice dialog::before {
        content: '';
        border-right: 8px solid #333;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        position: absolute;
        left: -8px;
        top: 156px;
      }
      .staticdevice:hover dialog{
        display: block;
      }
      .staticdevice dialog li {
        padding:5px 0px;
        display: flex;
        align-items: center;
        color: #999;
      }
      .staticdevice dialog li button {
        padding: 0px;
        text-align: left;
        display: block;
        color: #333;
      }
      .staticdevice dialog li button span{
        padding: 0px;
        text-align: left;
      }
      .staticdevice dialog li button:hover{
        background: none;
      }
      .staticdevice button svg {
        width: 15px;
        color: #3693e6;
      }
      .staticdevice button{
        color:#922791;
      }
      .staticdevice dialog span {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        display: flex;
        margin-right: 5px;
      }
      .staticdevice dialog b {
        font-weight: 400;
        padding-left: 5px;
      }
      .staticdevice h4 {
        padding: 6px 0px;
        color: #a4a4a4;
        font-weight: 400;
      }
      .colo1{
        background: #0795fb;
      }
      .colo2{
        background: #ffda78;
      }
      .colo3{
        background: #69cf01;
      }
      .colo4{
        background: #f44336;
      }
      .colo5{
        background: #cccecf;
      }
      .colo6 svg {
        color: red;
        width: 13px;
        height: 13px;
      }
      .colo7 svg {
        color: #999;
        width: 13px;
        height: 13px;
      }
      .bornon{
        border:0px;
        background: none;
      }
      .selecsmall{
        font-size: 13px !important;
min-height: 25px !important;
line-height: 25px !important;
      }
      .bg-greyl{
        background: #eeeded;
      }
    
      .leftpop span {
        display: block;
        font-size: 13px;
        margin-bottom: 5px;
      }
      .leftpop  h5{
        font-size: 16px;
        margin: 0px 0px 5px;
       }
      .leftpop span b{
       font-weight:500
      }
      .rightpop {
        padding-left:0px;
      }
      .closebtpop i {
        font-size: 19px;
        cursor: pointer;
      }
      .popmapicons {
        display: flex;
        justify-content: space-between;
        padding: 8px 6px 0px;
        background: #fff;
        margin-top: 9px;
        border-top: 1px solid #dedede;
        align-items: center;
      }
      .mapclick i{
        font-size: 25px;
        color:#fff
      }
      .mininmax {
        position: absolute;
        left: 390px;
top: 25px;
        z-index: 5;
        color: #fff;
        cursor: pointer;
        width: 25px;
        height: 25px;
        background: url("../../assets/images/mini-max.png");
        background-position:0px 0px;
        background-size: cover;
      }
      .mininmax.hiddiv {
        width: 35px;
        height: 35px;
        position: absolute;
        background:url("../../assets/images/mini-max.png") rgba(0,0,0,0.5);
        padding: 5px;
        left: 125px;
top: 25px;
        background-position:0px -70px;
        background-size: cover;
      }