.nan_auto_layout{
    margin-left: 99px;
height: 100%;
position: relative;
padding: 10px;
overflow: auto;
}
.flx_0{
    flex: 0 0 auto;
}
.flx_1{
    flex: 1 1 auto;
}
.report_left{
    width:250px;
    gap: 10px;
    position: fixed !important;
    top: 0;
    left: 111px;
    z-index: 2;
    padding: 10px 0px;
}
.head_main {
    background: #323E55;
    padding: 6px 10px;
    color: #ffffff;
  }
  .fs-7{
      font-size:10px
  }
  .bg_gray{
    background-color: #f4f4f5;
  }
.accor_head{
    position: relative;
background-color: #e4e4e4;
padding-left: 5px;
height: 34px;
line-height: 34px;
border-bottom: 1px solid #f9f2f2;
}
.or_acc_menu ul, .or_acc_menu li{
list-style: none;
margin: 0 auto;
padding: 0px;
}
.acco_link{
    height: 35px;
    line-height: 35px;
    padding-left: 15px;
    cursor: pointer;
    display: block;
    color: #666;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.acco_link:hover {
    background-color: #f5f7fa;
  }
.box_shadow{
    box-shadow: 2px 2px 4px rgba(0,0,0,.2),-2px -2px 4px rgba(0,0,0,.2);
    background-color: #fff;
}
.wh_np{
    white-space: nowrap
}
.txt_link {
    color: #2cabe3;
    cursor: pointer;
  }
  .pd_2 .txt_link{
    padding-left: 10px;
    padding-right: 10px;
    border-right: 1px solid #000000;
    line-height: 12px;
    display: inline-block;
  }
  .pd_2{
    border-bottom: 1px solid #ebeef5;
  }
  .body_box{
    padding: 15px;
    color: #303133;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    border-radius: 4px;
border: 1px solid #ebeef5;
background-color: #fff;
  }
  .txt_bold{
    font-size: 24px;
    font-weight: 600;
    color: #606266;
  }
  .btn-danger-2{
    background-color: #f56c6c !important;
    
  }
  .over_auto{
      overflow: auto;
  }

  .right_report {
    margin-left: 265px;
    box-shadow: 2px 2px 4px rgba(0,0,0,.2),-2px -2px 4px rgba(0,0,0,.2);
    background-color: #fff;
    overflow: auto;
    padding-bottom: 50px;
  }
  .right_report .panel_main{
    min-height: 100%;
  }
    
  @media only screen and (max-width:1439px){
    .right_report{
      white-space: nowrap !important;
      overflow: auto !important;
    }
    .right_report .panel_main{
      white-space: inherit !important;
min-width: 900px !important;
    }
  }
  
  .w-50c{
    width:200px;
  }
  .select2-selection__control {
    padding: 0px 0px;
    height: 36px;
    line-height: 12px;
    border-radius: 0px !important;
    min-height: 36px !important;
  }
  .select2-selection__value-container{
    padding: 0px 0px;
  }
  .datseparate span{
    font-size: 13px;
  }
  .posexcelsheet {
    position: absolute;
    background: #fff;
    bottom: 10px;
    z-index: 5;
    right: 25px;
    width: 83%;
    text-align: right;
    padding: 11px 0px;
  }
  .rmsc .dropdown-container {
    border-radius: 0px !important;
    height: 34px;
  }
  .rmsc .dropdown-heading {
    height: 33px !important;

  }
  .columwidt {
    width: 200px;
  }
  .select2-selection {
    border-radius: 0px !important;

  }
  .css-1okebmr-indicatorSeparator{
    display: none;
  }
  .select2-selection.w-50ctrript__control {
    border-radius: 0px !important;
    height: 35px !important;
    min-height: 35px !important;
    line-height: 35px !important;
    display: flex;
    align-items: flex-start;
  }
  .select2-selection.w-50ctrript__value-container {
    min-height: 34px !important;
    height: 34px !important;
    line-height: 34px !important;
  }
  .rmsc .gray {
    color: var(--rmsc-gray);
    font-size: 13px;
  }
  .w-50ctrript__placeholder{
    font-size: 13px;
  }
  .w-50ctrript{
    width:100px;
  }
  .columwidt a{
    white-space: normal;
width: 200px;
display: block;
  }
  .columsno{
    width:50px;
  }
  .columsloca{
    width:250px;
  }
  .columsloca a{
    white-space: normal;
    width: 250px;
    display: block;
  }
  .columwidtsingle a{
    white-space: normal;
    width: 400px;
    display: block;
  }