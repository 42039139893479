
.bus_col_2 *
{
    font-size:13px;
}
.cus_btn_1
{
    color: #606266;
    background: #fff;
    border: 1px solid #dbe1e5;
    border-radius: 2px;
    position: relative;
padding: 5px;
margin-left: 10px;
}
.plus_icon_1{
    color: #9d9d9d
}
.flx_1{
    flex:1 1 auto;
}
.flx_0{
    flex:0 0 auto;
}
.menu_bus_aside{
    font-size: 25px;
}
.info_icon{
    font-size: 14px;
    color: #20a8d8;
}
.rotate_90{
    transform: rotate(90deg);
}
.drop_cus_1 {
    background: transparent !important;
    border: 0 !important;
    padding: 0;
  }
  .drop_cus_1 i{
color:#313c50
  }
  .busin_tab_1 .nav-link {
    padding-left: 5px;
    padding-right: 5px;
  }
  .color_1{
    color:#313c50
  }
  .prof_img img{
height: 60px;
width: 60px;
border-radius: 50%;
  }
  .txt_colr_1{
    color: #2EC7E3 !important;
    cursor: pointer;
  }
  .cir_user{
      background: #f0f9fc;
      width: 40px;
      height: 40px;
      border-radius: 50%;

  }
  .br_1{
      border-right:1px solid #ececec;
  }

.large_txt{
    font-size:30px;
    color:#000000
}

.line-25{
    line-height: 26px;
}
.line-1{
    line-height: 1;
}
.txt-black{
    color:#000000;
}
.car_bal_cir{
    position: absolute;
    right: 0;
    bottom: 10px
}


.upload_cus{
    position: absolute;
overflow: hidden;
top: 5px;
right: 5px;
}
.upload_cus input {
    position: absolute;
    font-size: 50px;
    opacity: 0;
    right: 0;
    top: 0;
  }
  .upload_edit{
      width:30px;
      height: 30px;
      background: #313c50;
      color:#ffffff;
      cursor: pointer;
      border-radius: 50%;
  }
  .profile_img{
      width: 100px;
      height: 100px;
      border: 1px solid #ddd;
      border-radius: 5px;
  }
.btn-left{
    margin-right: 10px;
}

.cursor-pointer{
    cursor: pointer;
}
.br_1 {
    border-right: 1px solid #eff2f7;
  }
  #pageDropDown {
    color: #fff;
    background-color: #E98607;
    border-color: #E98607;
  }
  .over_auto{
    overflow: auto;
}
.wh_np{
    white-space: nowrap;
}

  .fw_ini{
    flex-wrap: initial !important;
  }
  .flx_0{
      flex:0 0 auto;
  }
  .flx_1{
    flex:1 1 auto;
}
.panel_x_scroll, .over_x_auto{
    overflow: auto !important;
}
  @media only screen and (max-width:1440px){
    .dash_col {
        min-width: 300px;
      }
    
    .wh_np_row{
        min-width: 900px !important ;
    }
    .main_scroll_dash{
        height: 88% !important;
    }
  }
  
  .custhei{
      height: 100%;
      background: red !important;
  }

  .ViewDeviceInfo {
    position: absolute;
    top: 111px;
    left: 0;
    right: 0;
    background: #ffffff;
    bottom: 0;
    overflow: auto;
    padding: 0px 20px 20px;
  }
  
  .showdetails {
    background: #E98607;
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 125px;
    text-align: center;
    bottom: -7px;
    cursor: pointer;
    color: #fff;
  }
  .selected-icon{
    border: 2px solid #3D4B64 !important;
  }