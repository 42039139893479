.cursor-pointer{
    cursor: pointer;
}
.tab_right_btn{
    right: 10px;
    top: 8px;
}
.tab_right_btn button{
padding-top: 5px;
padding-bottom: 5px;
}
.txt_link{
    color: #20a8d8 !important;
cursor: pointer;
outline: none;
}
.color_cir_read{
    width:8px;
    height: 8px;
    border-radius: 50%;
    background: #e6ebf5;
}
.color_cir_un_read{
    width:8px;
    height: 8px;
    border-radius: 50%;
    background: #e98607;
}
.selection-cell-header, .selection-cell{
width: 20px;
}
.bg_gray_2{
    background-color: rgba(220,226,230,.4);
padding: 15px;
border-bottom: 1px solid #e8e8e8;
color: #666;
}

.btn-right{
    margin-right: 10px;
}
.deleticons i{ 
    color:#2EC7E3;
}