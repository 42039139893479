.tabs_head_comnd .nav-tabs .nav-link{
    height: 35px;
    line-height: 35px;
    text-indent: 20px;
    cursor: pointer;
    color: #606266;
    padding: 0;
    display: block;
}
.tabs_head_comnd .nav-tabs .nav-link.active, 
.tabs_head_comnd .nav-tabs .nav-item.show .nav-link{
    background-color: #ffbf7f;
}
.com_des_2{
    white-space: normal;
}
.command_table .thead-light th{
    padding: 0 !important;
    box-shadow: none !important;
    background: transparent !important;
    border:0px !important
}
.command_table tbody{
    border-top: 0 !important;
}
.command_sched{
    position: absolute;
    right: 10px;
    text-align: right;
    top: 6px;
}
.txt_link_2 span{
    color: #ff9934;
    cursor: pointer;
    font-size: 13px;
    border-right:1px solid #ff9934;
    padding-left: 8px;
    padding-right: 8px;
    display: inline-block;
    line-height: 1;
}